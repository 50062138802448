export default {
  totalAmountMarginTop: '1rem',
  loanCalculatorBorderWidth: '2px',
  loanCalculatorValueColor: 'var(--green)',
  summaryButtonContent: '✓ ',
  summaryButtonSelectedBackground: 'var(--white)',
  summaryButtonSelectedColor: 'var(--teal)',
  userAgreementMarginTop: '.5rem',
  returningCustomerBankIDButtonColor: 'var(--mbc)',
};
